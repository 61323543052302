import './style.scss';
import React from 'react';
import { Duration, Label, Text } from '../../atoms';
import { useHistory } from 'react-router-dom';

type Props = {
    id: any;
    key: any;
    collection: any;
};

const CardBlockJourney = ({ collection }: Props) => {
    const cardType = collection.enrollment === true || collection.enrollment === false ? 'enrollments' : 'nodes';
    const history = useHistory();

    const classNotStartedYet = (item) => {
        if (!item?.data?.enrollment?.team?.initialDate) {
            return false;
        }
        const initialDate = new Date(item?.data?.enrollment?.team?.initialDate).getTime();
        const currentDate = new Date().getTime();
        return initialDate ? initialDate > currentDate : false;
    };

    switch (cardType) {
        case 'nodes': {
            const { label, text, title, duration } = collection.data;

            const { progress, status } = collection.data.enrollment;

            const progressPorc = progress && +progress > 0 ? `${Math.round(progress)}%` : '';
            let concluidedText = '';

            if (status === 'COMPLETED') {
                concluidedText = "<i class='fa fa-check' aria-hidden='true'></i> Concluído";
            } else if (status === 'NOT_ATTEMPTED' || status === undefined) {
                concluidedText = 'Não iniciado';
            } else if (status === 'IN_PROGRESS') {
                concluidedText = 'Em andamento';
            }

            const handleMouseEvent = (e: React.MouseEvent<HTMLDivElement>) => {
                e.preventDefault();

                if (collection.data.enrollment) {
                    history.push(`/level/${collection.data.enrollment.idEnrollment}`);
                }
            };

            return (
                <div
                    className={`cardBlockJourney ${
                        classNotStartedYet(collection) ? 'classNotStartedYet' : 'classStartedYet'
                    }`}
                    onClick={(e) => {
                        !classNotStartedYet(collection) && handleMouseEvent(e);
                    }}
                >
                    <Label text={label} />
                    {/* <Label text={getContentTypeLabel(idContentType)!="N/A"?getContentTypeLabel(idContentType):label} /> */}

                    <div className={`infosContainer`}>
                        <div className="headerInfos">
                            {title && <Text component="p" text={title} />}
                            {text && <Text component="p" text={text} />}
                        </div>

                        <div className="footerInfos">
                            <Text component="small" text={concluidedText} />

                            {progress > 0 && progress < 100 && <Text component="small" text={progressPorc} />}

                            {duration && <Duration text={duration} timeFormat={'seconds'} />}
                        </div>
                    </div>
                </div>
            );
        }

        case 'enrollments': {
            const { label, workload, title, nodeCode } = collection;
            const { progress } = collection.enrollment;
            const progressPorc = progress && +progress > 0 ? `${progress}%` : '';

            const concluidedText =
                progress === 100
                    ? `${"<i class='fa fa-check' aria-hidden='true'></i> Concluído"}`
                    : `${'Em andamento ' + progressPorc}`;

            const handleMouseEvent = (e: React.MouseEvent<HTMLDivElement>) => {
                console.log(collection);
                e.preventDefault();
                history.push(`/nodeTree/${nodeCode}`);
            };

            return (
                <div
                    className="cardBlockJourney"
                    onClick={(e) => {
                        handleMouseEvent(e);
                    }}
                >
                    <Label text={label} />
                    {
                        <div className={`infosContainer`}>
                            <div className="headerInfos">{title && <Text component="p" text={title} />}</div>

                            <div className="footerInfos">
                                <Text component="small" text={concluidedText} />
                                {workload && <Duration text={workload} timeFormat={'seconds'} />}
                            </div>
                        </div>
                    }
                </div>
            );
        }
    }
};

export default CardBlockJourney;
